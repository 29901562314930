import { Block } from '@/components/block';
import { BlockHeader } from '@/components/block/header';
import { ButtonGroup } from '@/components/button-group';
import { SplitTextBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const SplitTextBlock = ({
  id,
  firstColumn,
  secondColumn,
  variant,
  bodySize = 'md',
  noTopPadding = false,
}: SplitTextBlockProps) => {
  const { base, grid } = styles({
    variant,
    hasSingleColumn: !secondColumn,
  });

  const { heading, preHeading, body, buttons } = firstColumn || {};
  const {
    heading: secondHeading,
    preHeading: secondPreHeading,
    body: secondBody,
    buttons: secondButtons,
  } = secondColumn || {};

  return (
    <Block id={id} className={base()} noTopPadding={noTopPadding}>
      <div className={grid()}>
        <div>
          <BlockHeader heading={heading} preHeading={preHeading} body={body} variant={variant} bodySize={bodySize} />

          {buttons?.length ? <ButtonGroup buttons={buttons} className="mt-8 justify-start" size="lg" /> : null}
        </div>

        {secondColumn && (
          <div>
            <div>
              <BlockHeader
                heading={secondHeading}
                preHeading={secondPreHeading}
                body={secondBody}
                variant={variant}
                bodySize={bodySize}
              />

              {secondButtons?.length ? (
                <ButtonGroup buttons={secondButtons} className="mt-8 justify-start" size="lg" />
              ) : null}
            </div>
          </div>
        )}
      </div>
    </Block>
  );
};

const styles = tv({
  slots: {
    base: '[&_ul_li]:leading-snug',
    grid: 'grid-class grid-auto-rows-min relative grid justify-center gap-8 lg:grid-cols-2 lg:grid-rows-none lg:gap-16',
  },
  variants: {
    hasSingleColumn: {
      true: {
        grid: 'lg:grid-cols-[2fr_1fr]',
      },
    },
    variant: {
      light: {
        base: 'bg-white',
      },
      grey: {
        base: 'bg-grey-50',
      },
      dark: {},
    },
  },
});

export { SplitTextBlock };
