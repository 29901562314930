import { Block } from '@/components/block';
import { BlockHeader } from '@/components/block/header';
import { ButtonGroup } from '@/components/button-group';
import { Image } from '@/components/image';
import { FeatureHeaderBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const FeatureHeaderBlock = ({
  body,
  id,
  heading,
  image,
  variant = 'dark',
  preHeading,
  buttons,
}: FeatureHeaderBlockProps) => {
  const { base, wrapper, backgroundImage, imageCol, contentCol } = styles({
    variant,
  });

  return (
    <Block id={id} className={base()}>
      <div className={wrapper()}>
        <div className={contentCol()}>
          <div>
            <BlockHeader
              heading={heading}
              headingElement="h1"
              preHeading={preHeading}
              body={body}
              variant={variant}
              bodySize="xl"
            />

            {buttons && <ButtonGroup buttons={buttons} className="mt-8 justify-start" size="lg" />}
          </div>
        </div>

        <div className={imageCol()}>
          <Image data={image} className="rounded-md" />
        </div>

        <div className={backgroundImage()}>
          <Image src="/assets/angled-lines-bg.svg" layout="fill" />
        </div>
      </div>
    </Block>
  );
};

const styles = tv({
  slots: {
    base: 'relative overflow-hidden pt-28 max-lg:px-2 max-lg:pb-10 xl:pt-44',
    wrapper:
      'grid-auto-rows-min z-2 grid items-center justify-center gap-8 lg:grid-cols-2 lg:grid-rows-none lg:justify-normal lg:gap-10',
    imageCol: 'mx-auto w-full',
    contentCol: 'relative z-2 flex md:items-center lg:justify-normal',
    richText: 'text-md leading-tight lg:text-lg',
    backgroundImage:
      'pointer-events-none absolute bottom-0 left-1/2 top-0 z-1 hidden w-full -translate-x-1/2 md:block [&_svg]:max-w-full',
  },
  variants: {
    variant: {
      light: {},
      dark: {
        base: 'bg-blue-600',
      },
    },
  },
});

export { FeatureHeaderBlock };
