import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Block } from '@/components/block';
import { CoverageItem } from '@/components/coverage-item';
import { CoverageBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const CoverageBlock = ({
  blockAlignment = 'center',
  body,
  children,
  id,
  heading,
  variant,
  noTopPadding = false,
  coverage,
}: CoverageBlockProps) => {
  const [gridRef, inView] = useInView({
    threshold: 0.8,
  });
  const [animateItems, setAnimateItems] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimateItems(true);
    }
  }, [inView]);

  const { base, grid, coverageItem } = coverageBlockStyles({ variant });

  return (
    <div className={base()}>
      <Block id={id} heading={heading} body={body} blockAlignment={blockAlignment} noTopPadding={noTopPadding}>
        <div ref={gridRef} className={grid()}>
          {children ||
            coverage?.items.map((item, index) => {
              const { heading: itemHeading, subHeading, stat, icon } = item;
              return (
                <CoverageItem
                  heading={itemHeading}
                  subHeading={subHeading}
                  routeTypeColor={item.routeTypeColor}
                  stat={stat}
                  icon={icon}
                  image={item.image}
                  animateItem={animateItems}
                  index={index} // Used for the animation delay
                  key={`${itemHeading}-coverage-block`}
                  className={coverageItem()}
                />
              );
            })}
        </div>
      </Block>
    </div>
  );
};

const coverageBlockStyles = tv({
  slots: {
    base: 'text-text-primary [&_p]:text-text-primary',
    grid: 'mb-0 mt-8 grid grid-cols-1 items-start gap-x-12 gap-y-6 overflow-hidden p-2 min-[400px]:grid-cols-2 min-[400px]:grid-rows-3 md:mt-8 md:grid-cols-6 md:grid-rows-2 md:gap-y-12 xl:grid-cols-5 xl:grid-rows-none xl:gap-x-16',
    coverageItem:
      'relative md:max-xl:col-span-2 [&:nth-child(2n+2)>.divider]:min-[400px]:max-md:!hidden [&:nth-child(3n)>.divider]:md:hidden [&:nth-child(4)]:md:max-xl:col-start-2 [&:nth-child(5)]:min-[400px]:max-md:col-span-2 [&>.divider]:last:!hidden',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white',
      },
      grey: {
        base: 'bg-grey-50',
      },
    },
  },
});

export { CoverageBlock };
