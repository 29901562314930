import { Block } from '@/components/block';
import { Image } from '@/components/image';
import { ImageBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const ImageBlock = ({ id, image, variant = 'light', noTopPadding }: ImageBlockProps) => {
  const { base, imageStyles } = styles({ variant });

  return (
    <Block id={id} noTopPadding={noTopPadding} className={base()}>
      <Image data={image} className={imageStyles()} layout="responsive" />
    </Block>
  );
};

const styles = tv({
  slots: {
    base: null,
    imageStyles: 'relative overflow-hidden rounded-md',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white',
      },
      grey: {
        base: 'bg-grey-50',
      },
      dark: {},
    },
  },
});

export { ImageBlock };
