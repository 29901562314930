import { Block } from '@/components/block';
import { blockHeaderStyles, blockTextStyles } from '@/components/block/header';
import { ButtonGroup } from '@/components/button-group';
import { Heading } from '@/components/heading';
import { Image } from '@/components/image';
import { RichText } from '@/components/rich-text';
import { Text } from '@/components/text';
import { CtaBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const CtaBlock = ({
  id,
  heading,
  body,
  isCentered = false,
  buttons = [],
  headingVariant = 'h1',
  preHeading,
  preHeadingVariant = 'uppercase',
  noTopPadding = false,
  variant: blockVariant = 'light',
  image,
  bodySize = 'md',
}: CtaBlockProps) => {
  const {
    base,
    row,
    contentCol,
    headingCol,
    buttonGroup,
    richText,
    imageWrapper,
    preHeadingStyles,
    inner,
    headingStyles,
  } = ctaBlockStyles({
    isCentered,
    variant: blockVariant,
    hasBgImage: !!image,
    hasBody: !!body,
    bodySize,
  });

  return (
    <div className={base()}>
      <Block id={id} className="py-12 xl:p-24" noTopPadding={noTopPadding}>
        <div className={inner()}>
          {/* Separate pre-heading so the heading and the body line up regardless */}
          <div className={row()}>
            <div className={headingCol()}>
              {preHeading && (
                <Text variant={preHeadingVariant} className={preHeadingStyles()}>
                  {preHeading}
                </Text>
              )}
            </div>
          </div>
          <div className={row()}>
            <div className={headingCol()}>
              {heading && (
                <Heading variant={headingVariant} as="h2" className={headingStyles()}>
                  {heading}
                </Heading>
              )}
            </div>
            <div className={contentCol()}>
              {body && <RichText className={richText()}>{body}</RichText>}

              {buttons && <ButtonGroup buttons={buttons} className={buttonGroup()} size="lg" />}
            </div>
          </div>
          {image && (
            <figure className={imageWrapper()}>
              <Image data={image} objectFit="cover" layout="fill" />
            </figure>
          )}
        </div>
      </Block>
    </div>
  );
};

const ctaBlockStyles = tv({
  slots: {
    base: null,
    inner: null,
    row: 'row z-2 mx-auto block md:max-lg:w-10/12 lg:grid lg:max-w-none lg:grid-cols-2 lg:justify-normal lg:gap-8',
    contentCol: 'content-col flex flex-col justify-center gap-8 md:max-lg:col-start-2 md:max-lg:col-end-12',
    headingCol: 'md:max-lg:col-start-2 md:max-lg:col-end-12',
    buttonGroup: 'button-group items-start justify-start last:[&_li]:mb-0',
    richText: blockTextStyles.richText,
    imageWrapper: 'absolute bottom-0 left-0 right-0 top-0 z-0 outline',
    preHeadingStyles: blockTextStyles.preHeading,
    headingStyles: '',
  },
  variants: {
    variant: {
      light: {
        headingStyles: blockHeaderStyles.variants.variant.light.headingStyles,
      },
      dark: {
        base: 'bg-blue-600 text-white [&_p]:text-grey-200',
        preHeadingStyles: 'text-grey-300',
      },
    },
    hasBgImage: {
      true: {
        inner: 'relative overflow-hidden rounded-lg p-8 lg:p-10',
        headingStyles: 'leading-tight lg:mb-0 lg:text-xl',
        contentCol: 'lg:items-end lg:justify-center',
        richText: '',
        buttonGroup: '',
      },
    },
    isCentered: {
      true: {
        row: 'justify-center lg:block lg:grid-cols-none lg:justify-center lg:gap-none',
        contentCol: 'mx-auto lg:w-1/2',
        headingCol: 'mx-auto text-center lg:w-2/3',
        buttonGroup: 'items-center justify-center',
        richText: 'mx-auto text-center',
      },
    },
    bodySize: {
      md: {
        richText: blockHeaderStyles.variants.bodySize.md.richText,
      },
      lg: {},
      xl: {},
    },
    // Required for compound variants when there is a background image
    hasBody: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      hasBgImage: true,
      variant: 'dark',
      class: 'bg-transparent text-white',
    },
    {
      hasBody: true,
      hasBgImage: true,
      class: '[&_.content-col]:items-start',
    },
    {
      hasBody: false,
      hasBgImage: true,
      class: '[&_.content-col]:items-start lg:[&_.row]:grid-cols-[1fr_auto]',
    },
  ],
});

export { CtaBlock };
