import { Block } from '@/components/block';
import { BlockHeader } from '@/components/block/header';
import { ButtonGroup } from '@/components/button-group';
import { Heading } from '@/components/heading';
import { Text } from '@/components/text';
import { DynamicIcon } from '@/components/ui/dynamic-icon';
import { ProductFeaturesBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const ProductFeaturesBlock = ({
  blockAlignment,
  body,
  children,
  id,
  preHeading,
  heading,
  variant,
  blockLayout = 'horizontal',
  columns = 'two',
  buttons,
  noTopPadding = false,
  items,
}: ProductFeaturesBlockProps) => {
  const { base, header, layoutGrid, grid, iconWrapper, itemHeading } = styles({
    layoutVariant: blockLayout,
    columns,
    variant,
  });

  return (
    <div className={base()}>
      <Block id={id} noTopPadding={noTopPadding}>
        <div className={layoutGrid()}>
          {(heading || body) && (
            <header className={header()}>
              <div>
                <BlockHeader
                  heading={heading}
                  preHeading={preHeading}
                  body={body}
                  variant={variant}
                  blockAlignment={blockAlignment}
                />
                {buttons && <ButtonGroup buttons={buttons} className="mt-8 justify-start" size="lg" />}
              </div>
            </header>
          )}
          <ul className={grid()}>
            {children ||
              items?.map((item) => {
                const { id: itemId, title, description, iconName } = item;
                return (
                  <li key={itemId} className="flex flex-col">
                    <div className={iconWrapper()}>
                      <DynamicIcon name={iconName} size={32} className="text-lightBlue-500" />
                    </div>
                    <div className="flex-1">
                      <Heading variant="h4" as="h3" className={itemHeading()}>
                        {title}
                      </Heading>
                      <Text variant="baseLg" className="mb-0 mt-0 text-grey-500">
                        {description}
                      </Text>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </Block>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'relative',
    layoutGrid: 'relative grid max-lg:gap-y-10',
    grid: 'grid gap-x-12 gap-y-8',
    header: '',
    iconWrapper: 'mb-4 grid size-8 place-items-center',
    itemHeading: 'mb-1 text-blue-700',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white',
      },
      grey: {
        base: 'bg-grey-50',
      },
      dark: {},
    },
    layoutVariant: {
      vertical: {
        layoutGrid: 'gap-y-10',
      },
      horizontal: {
        layoutGrid: 'grid-cols-1 gap-x-12 last:pt-2 lg:grid-cols-[1fr_2fr]',
        header: 'lg:sticky lg:top-8 lg:h-fit',
      },
    },
    columns: {
      two: {
        grid: 'grid-cols-1 md:grid-cols-2',
      },
      three: {
        grid: 'grid-cols-1 md:grid-cols-3',
      },
      four: {
        grid: 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4',
      },
    },
  },
});

export { ProductFeaturesBlock };
