import { Block } from '@/components/block';
import { TeamCard } from '@/components/team-card';
import { TeamBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const TeamBlock = ({
  blockAlignment,
  body,
  children,
  id,
  heading,
  variant = 'light',
  noTopPadding = false,
  items,
}: TeamBlockProps) => {
  const { base, grid } = teamBlockStyles({ variant });
  return (
    <div id="team" className={base()}>
      <Block id={id} heading={heading} body={body} blockAlignment={blockAlignment} noTopPadding={noTopPadding}>
        <div className={grid()}>
          {children ||
            items?.map((item) => {
              const { name, body: itemBody, email, jobTitle, id: itemId, image } = item;
              return (
                <TeamCard
                  name={name}
                  body={itemBody}
                  email={email}
                  image={image}
                  jobTitle={jobTitle}
                  id={itemId}
                  key={itemId}
                />
              );
            })}
        </div>
      </Block>
    </div>
  );
};

const teamBlockStyles = tv({
  slots: {
    base: 'text-text-primary [&_p]:text-text-primary',
    grid: 'grid grid-cols-2 items-start gap-x-16 gap-y-12 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 lg:gap-16',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white',
      },
      grey: {
        base: 'bg-grey-50',
      },
      dark: {},
    },
  },
});

export { TeamBlock };
