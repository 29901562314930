import { Camera } from 'lucide-react';
import { Image, StructuredText } from 'react-datocms';

import { Block } from '@/components/block';
import { ConditionalWrapper } from '@/components/conditional-wrapper';
import { Container } from '@/components/container';
import { Link } from '@/components/link';
import { RichText } from '@/components/rich-text';
import { Text } from '@/components/text';
import { BlockImage, LinkInterface, RichTextBlockProps } from '@/types/block-types';
import { getDatoLinkHref } from '@/utils/helpers/urls';
import { tv } from '@/utils/styles';

const RichTextBlock = ({
  body,
  id,
  blockAlignment = 'left',
  variant: blockVariant,
  blockPosition = 'center',
  noTopPadding,
  heading,
  __typename,
  preHeading,
  hasSlimContainer,
}: RichTextBlockProps) => {
  const { base, image, inner } = styles({ variant: blockVariant, blockPosition });
  return (
    <div className={base()}>
      <Container
        className="px-none lg:px-none" // To remove double margins as there is already a <Container /> component inside <Block />
      >
        <div className={inner()}>
          <div className="w-8/12">
            <Block
              blockAlignment={blockAlignment}
              id={id}
              hasButtonsAtTheBottom
              body={null}
              noTopPadding={noTopPadding}
              heading={heading}
              preHeading={preHeading}
              hasSlimContainer={hasSlimContainer}
              __typename={__typename}
            >
              <RichText isDatoStructuredText>
                <StructuredText
                  data={body}
                  renderBlock={({ record }) => {
                    switch (record.__typename) {
                      case 'ImageRecord': {
                        const { internalLink, externalLink } = record.link as LinkInterface[][0];

                        const imageLink = getDatoLinkHref({
                          internalLink,
                          externalLink,
                        });

                        const figCaption = (record.image as BlockImage)?.responsiveImage?.title;

                        return (
                          <ConditionalWrapper
                            condition={!!imageLink}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            wrapper={(children) => (
                              <Link href={imageLink!} className="no-underline">
                                {children}
                              </Link>
                            )}
                          >
                            {/* 'not-rich-text' class used to disable the Tailwind typography plugin styles on images - https://github.com/tailwindlabs/tailwindcss-typography?tab=readme-ov-file#undoing-typography-styles */}
                            <div className="not-rich-text">
                              <figure className="my-4 lg:my-xl">
                                <div className="mx-0 lg:-mx-7">
                                  <Image
                                    className={image({ isLink: !!imageLink })}
                                    data={(record.image as BlockImage).responsiveImage}
                                    layout="responsive"
                                  />
                                </div>
                                {figCaption && (
                                  <figcaption className="mt-3 flex items-center">
                                    <Camera size={24} className="mr-2 text-grey-500" />
                                    <Text variant="secondary" as="span" className="text-grey-500">
                                      {figCaption}
                                    </Text>
                                  </figcaption>
                                )}
                              </figure>
                            </div>
                          </ConditionalWrapper>
                        );
                      }
                      default:
                        return null;
                    }
                  }}
                />
              </RichText>
            </Block>
          </div>
        </div>
      </Container>
    </div>
  );
};

const styles = tv({
  slots: {
    base: '',
    inner: 'flex flex-wrap',
    image: 'rounded transition duration-200 ease-in-out',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white text-text-primary [&_p]:text-text-primary',
      },
      grey: {
        base: 'bg-grey-50 text-text-primary [&_p]:text-text-primary',
      },
      dark: {
        base: 'bg-blue-600 text-white [&_a]:text-white [&_h2]:text-white [&_h3]:text-white [&_h4]:text-white [&_p]:text-white',
      },
    },
    isLink: {
      true: {
        image: 'hover:shadow-[0_0_0_4px] hover:shadow-blue-400',
      },
    },
    blockPosition: {
      left: {
        inner: 'flex flex-wrap justify-start',
      },
      center: {
        inner: 'flex flex-wrap justify-center',
      },
    },
  },
});

export { RichTextBlock };
