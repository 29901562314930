/**
 * AnimatePresence from framer-motion _can_ be used for exit animations
 * in conjunction with the forceMount prop on the TabsContent component,
 * but you have to position the content absolutely. This was doable with
 * images but not with rich text as it could be any size.
 */

import * as TabsPrimitive from '@radix-ui/react-tabs';
import { motion } from 'framer-motion';
import { first } from 'lodash';
import { useState } from 'react';
import { StructuredText } from 'react-datocms';

import { Block } from '@/components/block';
import { Heading } from '@/components/heading';
import { Image } from '@/components/image';
import { RichText } from '@/components/rich-text';
import { Text } from '@/components/text';
import { DynamicIcon } from '@/components/ui/dynamic-icon';
import { useWindowSize } from '@/hooks/use-window-size';
import { BREAKPOINTS_AS_NUMBERS } from '@/theme/constants/breakpoints';
import { JumboTabsBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const JumboTabsBlock = ({ id, heading, preHeading, items, noTopPadding, variant = 'grey' }: JumboTabsBlockProps) => {
  const firstItemId = first(items)?.id;
  const { width } = useWindowSize();
  const [activeTab, setActiveTab] = useState(firstItemId);
  const isSmallScreen = width ? width < BREAKPOINTS_AS_NUMBERS.LG : false;

  const handleTabChange = (value: string) => {
    setActiveTab(value);
  };

  const motionVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.6,
        ease: 'easeInOut',
        type: 'spring',
        delay: 0.1,
      },
    },
    hidden: {
      opacity: 0,
      x: isSmallScreen ? 0 : 50,
      transition: {
        duration: 0.3,
        delay: 0,
      },
    },
  };

  const {
    base,
    inner,
    tabsList,
    content,
    contentHeading,
    contentSummary,
    contentInner,
    tabTrigger,
    tabHeading,
    tabSummary,
    tabBg,
    icon,
  } = styles({
    variant,
  });

  return (
    <Block id={id} heading={heading} preHeading={preHeading} noTopPadding={noTopPadding} className={base()}>
      <TabsPrimitive.Root defaultValue={firstItemId} orientation="vertical" onValueChange={handleTabChange}>
        <div className={inner()}>
          <TabsPrimitive.List className={tabsList()}>
            {items?.map((item) => {
              const itemId = item.id;
              return (
                <TabsPrimitive.Trigger key={itemId} value={itemId} className={tabTrigger()}>
                  {item.icon && <DynamicIcon name={item.icon} size={28} className={icon()} />}
                  <div>
                    <Heading variant="h4" as="h3" className={tabHeading()}>
                      {item.heading}
                    </Heading>
                    {item.summary && <Text className={tabSummary()}>{item.summary}</Text>}
                  </div>
                  <span className={tabBg()} />
                </TabsPrimitive.Trigger>
              );
            })}
          </TabsPrimitive.List>

          <div className={content()}>
            {items?.map((item) => {
              const itemId = item.id;
              return (
                <TabsPrimitive.Content value={itemId} asChild key={itemId}>
                  <motion.div
                    initial="hidden"
                    animate={activeTab === itemId ? 'visible' : 'hidden'}
                    variants={motionVariants}
                    key={itemId}
                    className={contentInner()}
                  >
                    <div className="lg:hidden">
                      <Heading variant="h2" as="h3" className={contentHeading()}>
                        {item.heading}
                      </Heading>
                      {item.summary && (
                        <Text className={contentSummary()} variant="baseLg">
                          {item.summary}
                        </Text>
                      )}
                    </div>
                    {item.image && <Image data={item.image} objectFit="cover" layout="responsive" />}
                    {item.body && (
                      <RichText isDatoStructuredText className="max-lg:mb-5">
                        <StructuredText data={item.body} />
                      </RichText>
                    )}
                  </motion.div>
                </TabsPrimitive.Content>
              );
            })}
          </div>
        </div>
      </TabsPrimitive.Root>
    </Block>
  );
};

const styles = tv({
  slots: {
    base: 'relative overflow-x-hidden',
    inner: 'grid lg:-mt-2 lg:grid-cols-[360px_1fr] lg:gap-10 xl:grid-cols-[468px_1fr] xl:gap-x-12',
    tabsList: 'flex flex-col gap-2 md:gap-3.5 md:max-lg:flex-row',
    content: 'relative max-lg:mt-6',
    contentInner: 'flex flex-col gap-6 lg:gap-8',
    contentSummary: 'mb-0',
    contentHeading: 'mb-4 text-blue-500',
    tabTrigger:
      'group relative flex w-full rounded-lg p-4 text-left max-lg:bg-grey-200 max-md:items-center md:p-5 md:max-lg:flex-col md:max-lg:gap-3 lg:p-6 [&_*]:relative [&_*]:z-2',
    tabHeading: 'mb-0 text-blue-500 lg:mb-2',
    tabSummary: 'm-0 text-baseLg leading-6 text-text-primary transition-colors max-xl:text-base max-lg:hidden',
    tabBg:
      'tab-bg !absolute bottom-0 left-0 right-0 top-0 !z-1 block scale-[.95] rounded-lg border-2 border-grey-300 opacity-0 transition-all duration-100 ease-in-out group-hover:scale-100 group-hover:opacity-100 group-data-[state=active]:scale-100 group-data-[state=active]:border-lightBlue-500 group-data-[state=active]:bg-white group-data-[state=active]:opacity-100 group-data-[state=active]:shadow-soft',
    icon: 'mr-3 text-lightBlue-500',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white',
      },
      grey: {
        base: 'bg-grey-100',
      },
    },
  },
});

export { JumboTabsBlock };
