import { Globe } from 'lucide-react';

import { Image } from '@/components/image';
import { tv } from '@/utils/styles';

export interface TestimonialProps {
  quote: string;
  author: string;
  jobTitle?: string;
  companyName: string;
  url?: string;
  image?: {
    responsiveImage: {
      src: string;
      alt: string;
      width: number;
      height: number;
      aspectRatio: number;
    };
  };
  className?: string;
}

const Testimonial = ({ quote, author, jobTitle, companyName, url, image, className }: TestimonialProps) => {
  const {
    base,
    quoteStyles,
    authorWrapper,
    imageStyles,
    authorInfo,
    company,
    authorName,
    jobTitleStyles,
    companyNameStyles,
    icon,
  } = styles();

  return (
    <blockquote className={base({ className })}>
      <div
        className={quoteStyles()}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: quote }}
      />
      <div className={authorWrapper()}>
        {image?.responsiveImage && <Image data={image} className={imageStyles()} />}

        <footer className={authorInfo()}>
          <p className={authorName()}>{author}</p>
          {jobTitle && <p className={jobTitleStyles()}>{jobTitle}</p>}

          {url ? (
            <a href={url} className={company()} target="_blank" rel="noopener noreferrer">
              <Globe className={icon()} />
              <span className={companyNameStyles()}>{companyName}</span>
            </a>
          ) : (
            <p className={company()}>{companyName}</p>
          )}
        </footer>
      </div>
    </blockquote>
  );
};

const styles = tv({
  slots: {
    base: 'max-w-4xl rounded-lg bg-brandSoftBlue p-8',
    quoteStyles: 'blockquote text-body mb-6 leading-6 text-brandDarkBlue [&_p]:inline',
    authorWrapper: 'flex items-center gap-4',
    imageStyles: 'size-20 rounded-full object-cover',
    authorInfo: 'flex flex-col',
    authorName: 'text-body mb-0 font-semibold',
    jobTitleStyles: 'text-gray-600 -mt-1 mb-0',
    company: 'mb-0 flex items-center gap-x-1',
    companyNameStyles: 'text-lightBlue-500 underline underline-offset-4 hover:no-underline',
    icon: 'size-4 text-lightBlue-500',
  },
});

export { Testimonial };
