import { Block } from '@/components/block';
import { Testimonial } from '@/components/ui/testimonial';
import { TestimonialBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const TestimonialBlock = ({
  id,
  heading,
  body,
  testimonials,

  blockAlignment = 'center',
}: TestimonialBlockProps) => {
  const { base, grid, testimonial } = styles();

  return (
    <Block id={id} heading={heading} body={body} blockAlignment={blockAlignment} className={base()}>
      <div className={grid({ count: testimonials.length })}>
        {testimonials.map((item, i) => {
          const isFirstWhenThree = testimonials.length === 3 && i === 0;

          return <Testimonial key={`${item.author}-${i}`} {...item} className={testimonial({ isFirstWhenThree })} />;
        })}
      </div>
    </Block>
  );
};

const styles = tv({
  slots: {
    base: 'mx-auto',
    grid: 'grid gap-6',
    testimonial: '',
  },
  variants: {
    count: {
      1: {
        grid: 'mx-auto max-w-4xl grid-cols-1',
      },
      2: {
        grid: 'grid-cols-1 md:grid-cols-2',
      },
      3: {
        grid: 'grid-cols-1 md:grid-cols-2 [&>*:first-child]:md:col-span-2',
      },
      4: {
        grid: 'grid-cols-1 md:grid-cols-2',
      },
    },
    isFirstWhenThree: {
      true: {
        testimonial: 'mx-auto max-w-2xl',
      },
    },
  },
});

export { TestimonialBlock };
