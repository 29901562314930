import { isEmpty } from 'lodash';

import { Block } from '@/components/block';
import { Image } from '@/components/image';
import { Marquee } from '@/components/ui/marquee';
import { LogoPromoBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const LogoPromoBlock = ({
  blockAlignment,
  body,
  id,
  heading,
  variant = 'light',
  noTopPadding = false,
  logoList,
  logos,
  isMarquee = false,

  imageSize = 'default',
}: LogoPromoBlockProps) => {
  const { base, grid, logo, datoLogo, marquee, leftGradient, rightGradient } = styles({
    variant,
    isMarquee,
    imageSize,
  });
  const logoArr = logoList?.split(',') || [];

  return (
    <div className={base()}>
      <Block
        id={id}
        heading={heading}
        headingElement="h2"
        body={body}
        blockAlignment={blockAlignment}
        noTopPadding={noTopPadding}
      >
        {isMarquee ? (
          <div className={marquee()}>
            <Marquee pauseOnHover>
              {!isEmpty(logos) && logos
                ? logos.map((logoImage) => (
                    <div key={logoImage.id} className={datoLogo()}>
                      <Image data={logoImage} />
                    </div>
                  ))
                : logoArr.map((logoPath) => (
                    <div key={logoPath}>
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img src={logoPath} alt="Brand logo" className={logo()} />
                    </div>
                  ))}
            </Marquee>
            <div className={leftGradient()} />
            <div className={rightGradient()} />
          </div>
        ) : (
          <ul className={grid()}>
            {!isEmpty(logos) && logos
              ? logos.map((logoImage) => (
                  <li key={logoImage.id} className={datoLogo()}>
                    <Image data={logoImage} />
                  </li>
                ))
              : logoArr.map((logoPath) => (
                  <li key={logoPath}>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img src={logoPath} alt="Brand logo" className={logo()} />
                  </li>
                ))}
          </ul>
        )}
      </Block>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'text-text-primary [&_h2]:mb-4',
    grid: 'mx-auto -mt-6 flex flex-wrap justify-center',
    logo: 'm-4 max-h-10',
    marquee:
      'relative mx-auto flex w-full max-w-3xl flex-col items-center justify-center overflow-hidden max-md:-mx-4 max-md:w-[calc(100%+2rem)] md:-mt-2',
    leftGradient: 'pointer-events-none absolute inset-y-0 left-0 w-6 bg-gradient-to-r',
    rightGradient: 'pointer-events-none absolute inset-y-0 right-0 w-6 bg-gradient-to-l',
    datoLogo: 'm-4 [&_img]:max-h-10 [&_img]:w-auto [&_img]:object-contain',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white [&_p]:text-grey-600',
        leftGradient: 'from-white',
        rightGradient: 'from-white',
      },
      grey: {
        base: 'bg-grey-50 [&_p]:text-grey-600',
        leftGradient: 'from-grey-100',
        rightGradient: 'from-grey-100',
      },
    },
    isMarquee: {
      true: {
        logo: 'mx-2 my-0 max-h-12',
        datoLogo: 'mx-2 my-0 [&_img]:max-h-12 [&_img]:w-auto [&_img]:object-contain',
      },
    },
    imageSize: {
      default: {
        grid: 'max-w-[--narrow-center-content-max-width]',
      },
      lg: {
        logo: 'max-h-24',
        datoLogo: '[&_img]:max-h-24 [&_img]:w-auto [&_img]:object-contain',
        grid: 'max-w-[--container-max-width-slim]',
      },
    },
  },
});

export { LogoPromoBlock };
