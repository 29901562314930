import { Accordion } from '@/components/accordion';
import { Block, blockAlignmentOptions } from '@/components/block';
import { BlockHeader } from '@/components/block/header';
import { AccordionBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const AccordionBlock = ({
  id,
  heading,
  body,
  variant,
  blockAlignment = 'left',
  blockLayout = 'horizontal',
  items = [],
  allowMultipleExpanded,
  preExpandFirstItem,
  noTopPadding = false,
  className,
  ...props
}: AccordionBlockProps) => {
  const { base, block, grid, headingWrapper, accordionWrapper } = styles({
    blockLayout,
    variant,
  });

  return (
    <div className={base()}>
      <Block
        id={id}
        blockAlignment={blockAlignment}
        noTopPadding={noTopPadding}
        className={block({ className })}
        textColorVariant={variant}
        {...props}
      >
        <div
          style={{
            alignItems: blockAlignmentOptions[blockAlignment],
            textAlign: blockAlignment,
          }}
          className={grid()}
        >
          <div className={headingWrapper()}>
            <BlockHeader heading={heading} body={body} blockAlignment={blockAlignment} variant={variant} />
          </div>
          <div className={accordionWrapper()}>
            <Accordion
              id={id}
              items={items}
              allowMultipleExpanded={allowMultipleExpanded}
              preExpandFirstItem={preExpandFirstItem}
            />
          </div>
        </div>
      </Block>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'text-text-primary',
    accordionWrapper: 'max-w-5xl',
    block: null,
    grid: null,
    headingWrapper: 'mb-12',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white',
      },
      grey: {
        base: 'bg-grey-50',
      },
    },
    blockLayout: {
      horizontal: {
        grid: 'grid grid-cols-[1fr] gap-x-12 lg:grid-cols-[1fr_2fr] [&>*:last-child]:pt-2',
        headingWrapper: 'lg:sticky lg:top-8 lg:h-fit',
      },
      vertical: {
        grid: 'flex flex-col',
      },
    },
  },
});

export { AccordionBlock };
